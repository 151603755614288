import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { streamCollection } from "services/firebase";

type InitialState = {
  user: null | User;
  isLoading: boolean;
  error: null | string;
  selectedRole: null | string;
  isEditingProfile: boolean;
};

const initialState: InitialState = {
  user: null,
  isLoading: false,
  error: null,
  selectedRole: null,
  isEditingProfile: false,
};

const UserSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSelectedRole: (state, action) => {
      state.selectedRole = action.payload;
    },
    setIsEditingProfile: (state, action) => {
      state.isEditingProfile = action.payload;
    },
  },
});

export const UserSelector = (): InitialState =>
  useSelector((state: any) => state.user);
export const UserActions = UserSlice.actions;
export const UserReducer = UserSlice.reducer;
