import { Box, Container, Stack } from "@mui/material";
import CustomLink from "components/customs/CustomLink";
import ThemeModeMenu from "components/ThemeModeMenu";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  ApplicationsSelector,
  StreamApplications,
} from "redux/slices/applications";
import {
  PreferencesActions,
  PreferencesSelector,
  StreamAwardCategories,
  StreamPreferences,
  StreamUserRoles,
  StreamYearScores,
} from "redux/slices/preferences";
import { UserActions, UserSelector } from "redux/slices/user";
import { StreamUsers } from "redux/slices/users";
import AccountMenu from "./AccountMenu";
import Footer from "../Footer";

const AuthGuard = () => {
  const dispatch = useDispatch();
  const { user, selectedRole } = UserSelector();
  const { years, selectingYear, activeYear } = PreferencesSelector();
  const { filterYear } = ApplicationsSelector();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  if (
    !user &&
    !pathname.includes("/auth/login") &&
    !pathname.includes("/auth/register")
  ) {
    return <Navigate to="/auth/login" replace />;
  }

  useEffect(() => {
    // Initialize Preferences
    StreamPreferences({ dispatch });
    // Initialize Users
    StreamUsers({ dispatch });
    // Initialize Award Categories
    StreamAwardCategories({ dispatch });
    // Initialize User Roles
    StreamUserRoles({ dispatch });
  }, []);

  // Initialize Default Selecting Year
  useEffect(() => {
    if (selectingYear === null && years?.length > 0 && activeYear) {
      const _selectingYear = {
        label: activeYear?.id,
        value: activeYear?.id,
      };
      dispatch(PreferencesActions.setSelectingYear(_selectingYear));
    }
  }, [years, activeYear]);

  // Initialize Year Score
  useEffect(() => {
    StreamYearScores({
      dispatch,
      year: years?.find((y) => y.active === true),
    });
  }, [years]);

  // Validate User Role
  useEffect(() => {
    if (user && selectedRole && user?.roles) {
      if (user?.roles?.length === 0) {
        dispatch(UserActions.setUser(null));
        dispatch(UserActions.setSelectedRole(null));
        navigate("/");
      } else if (!user?.roles?.includes(selectedRole)) {
        dispatch(UserActions.setSelectedRole(user?.roles[0]));
        navigate("/");
      }
    }
  }, [user?.roles]);

  // Initialize Applications
  useEffect(() => {
    StreamApplications({
      dispatch,
      role: selectedRole,
      user_id: user?.id,
      assigned_awards: user?.assigned_awards?.map((award: any) => award.value),
      // filterYear: "2022",
      // if filterYear is null, it will be the active year
      filterYear: filterYear || activeYear?.id,
    });
  }, [selectedRole, filterYear]);

  return (
    <>
      <Container maxWidth={"lg"}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: "32px" }}
          flexWrap="wrap"
        >
          <Box component="a" target="_blank" href="https://mptc.gov.kh/">
            <Box
              component="img"
              src="https://firebasestorage.googleapis.com/v0/b/cicta-registration.appspot.com/o/left_logo.png?alt=media&token=2da9d35f-5be2-45f4-879d-1df753affa85"
              sx={{
                objectFit: "cover",
                width: { md: "470px", xs: "170px" },
                objectPosition: "center",
              }}
            />
          </Box>

          <CustomLink to="/">
            <Box
              component="img"
              src="https://firebasestorage.googleapis.com/v0/b/cicta-registration.appspot.com/o/logo.png?alt=media&token=8e195eb5-ce4b-40d4-86e2-9b326274c794"
              sx={{
                objectFit: "cover",
                width: { md: "120px", xs: "60px" },
                objectPosition: "center",
              }}
            />
          </CustomLink>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ my: "32px" }}
        >
          <AccountMenu />
        </Stack>
      </Container>
      <Outlet />
      {/* <Footer /> */}
    </>
  );
};

export default AuthGuard;
