import {
  getDocs,
  setDoc,
  addDoc,
  query,
  collection,
  doc,
  collectionGroup,
  QueryConstraint,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";
import { db } from "utils/firebase";

// Stream all documents from a collection with query
type StreamCollectionType = {
  collectionName: string;
  onGetResult: (result: any) => void;
  queryConstraints?: QueryConstraint[];
  onEmptyResult?: () => void;
};
export const streamCollection = async (props: StreamCollectionType) => {
  if (!props.queryConstraints) {
    props.queryConstraints = [];
  }
  const q = query(
    collection(db, props.collectionName),
    ...props.queryConstraints
  );

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    if (querySnapshot.empty) {
      props.onEmptyResult();
    }
    const _result = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
    props.onGetResult(_result);
  });
};

// Create a document in a collection
type CreateDocType = {
  collectionName: string;
  data: any;
  onSuccess: (result: any) => void;
  onError: (error: any) => void;
};
export const createDocument = async (props: CreateDocType) => {
  try {
    const docRef = doc(db, props.collectionName);
    const result = await setDoc(docRef, props.data);
    props.onSuccess(result);
  } catch (error) {
    props.onError(error);
  }
};

// Update a document in a collection
type UpdateDocType = {
  collectionName: string;
  docId: string;
  data: any;
  onSuccess: (result: any) => void;
  onError: (error: any) => void;
};
export const updateDocument = async (props: UpdateDocType) => {
  try {
    const docRef = doc(db, props.collectionName, props.docId);
    const result = await setDoc(docRef, props.data);
    props.onSuccess(result);
  } catch (error) {
    props.onError(error);
  }
};

// Delete a document in a collection
type DeleteDocType = {
  collectionName: string;
  docId: string;
  onSuccess: (result: any) => void;
  onError: (error: any) => void;
};
export const deleteDocument = async (props: DeleteDocType) => {
  try {
    const docRef = doc(db, props.collectionName, props.docId);
    const result = await deleteDoc(docRef);
    props.onSuccess(result);
  } catch (error) {
    props.onError(error);
  }
};
