// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";

const firebaseConfig = {
  apiKey: "AIzaSyB5WlqoP0EXOXWocPoCOVMhY1zJXcj5m8c",
  authDomain: "cicta-registration.firebaseapp.com",
  projectId: "cicta-registration",
  storageBucket: "cicta-registration.appspot.com",
  messagingSenderId: "129149149380",
  appId: "1:129149149380:web:015ebc2de2ceb822752991",
  measurementId: "G-Q004YGCW0G",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);

// Upload File To Firebase Bucket
export const uploadFile = async (
  file: any,
  type: "image" | "video" | "application"
): Promise<string> => {
  {
    const fileName = `/${type}s/${file.name + v4()}`;

    const storageRef = ref(storage, fileName);

    await uploadBytes(storageRef, file);
    // get image url
    const url = await getDownloadURL(storageRef);
    return url;
  }
};
