import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { streamCollection } from "services/firebase";
import { db } from "utils/firebase";

type InitialState = {
  isLoading: boolean;
  error: null | string;
  years: null | Year[];
  activeYear: null | Year;
  selectingYear: null | {
    label: string;
    value: string;
  };
  awardCategories: null | AwardCategory[];
  userRoles: null | UserRole[];

  // Award Categories
  isCreatingAwardCategory: boolean;
  currentUpdatingAwardCategory: null | AwardCategory;
  currentViewingAwardCategory: null | AwardCategory;

  // Scores
  yearScores: null | YearScore[];
  isCreatingScore: boolean;
  currentUpdatingScore: null | YearScore;
  currentViewingScore: null | YearScore;

  isCreatingYear: boolean;
};

const initialState: InitialState = {
  isLoading: false,
  error: null,
  years: null,
  activeYear: null,
  awardCategories: null,
  selectingYear: null,
  isCreatingAwardCategory: false,
  currentUpdatingAwardCategory: null,
  currentViewingAwardCategory: null,
  isCreatingScore: false,
  currentUpdatingScore: null,
  currentViewingScore: null,
  userRoles: null,
  yearScores: null,
  isCreatingYear: false,
};

export const PreferencesSlice = createSlice({
  name: "preferences",
  initialState: initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setYears: (state, action: PayloadAction<Year[] | null>) => {
      state.years = action.payload;
    },
    setActiveYear: (state, action: PayloadAction<Year | null>) => {
      state.activeYear = action.payload;
    },
    setAwardCategories: (
      state,
      action: PayloadAction<AwardCategory[] | null>
    ) => {
      state.awardCategories = action.payload;
    },
    setSelectingYear: (
      state,
      action: PayloadAction<{
        label: string;
        value: string;
      } | null>
    ) => {
      state.selectingYear = action.payload;
    },
    setCreatingAwardCategory: (state, action: PayloadAction<boolean>) => {
      state.isCreatingAwardCategory = action.payload;
    },
    setCurrentUpdatingAwardCategory: (
      state,
      action: PayloadAction<AwardCategory | null>
    ) => {
      state.currentUpdatingAwardCategory = action.payload;
    },
    setCurrentViewingAwardCategory: (
      state,
      action: PayloadAction<AwardCategory | null>
    ) => {
      state.currentViewingAwardCategory = action.payload;
    },
    setCreatingScore: (state, action: PayloadAction<boolean>) => {
      state.isCreatingScore = action.payload;
    },
    setCurrentUpdatingScore: (
      state,
      action: PayloadAction<YearScore | null>
    ) => {
      state.currentUpdatingScore = action.payload;
    },
    setCurrentViewingScore: (
      state,
      action: PayloadAction<YearScore | null>
    ) => {
      state.currentViewingScore = action.payload;
    },
    setUserRoles: (state, action: PayloadAction<UserRole[] | null>) => {
      state.userRoles = action.payload;
    },
    setYearScores: (state, action: PayloadAction<YearScore[] | null>) => {
      state.yearScores = action.payload;
    },
    setCreatingYear: (state, action: PayloadAction<boolean>) => {
      state.isCreatingYear = action.payload;
    },
  },
});

export const PreferencesSelector = (): InitialState =>
  useSelector((state: any) => state.preferences);
export const PreferencesActions = PreferencesSlice.actions;
export const PreferencesReducer = PreferencesSlice.reducer;

export const StreamPreferences = async ({
  dispatch,
}: {
  dispatch: Dispatch;
}) => {
  dispatch(PreferencesActions.setLoading(true));
  dispatch(PreferencesActions.setError(null));
  try {
    streamCollection({
      collectionName: "years",
      onGetResult: (result) => {
        const activeYear = result?.find((year: Year) => year.active === true);
        dispatch(PreferencesActions.setYears(result));
        dispatch(PreferencesActions.setActiveYear(activeYear));
      },
      onEmptyResult: () => {
        dispatch(PreferencesActions.setYears(null));
      },
      queryConstraints: [],
    });
  } catch (error) {
    dispatch(PreferencesActions.setError(error.message));
  } finally {
    dispatch(PreferencesActions.setLoading(false));
  }
};

export const StreamUserRoles = async ({ dispatch }: { dispatch: Dispatch }) => {
  dispatch(PreferencesActions.setLoading(true));
  dispatch(PreferencesActions.setError(null));
  try {
    streamCollection({
      collectionName: "roles",
      onGetResult: (result) => {
        dispatch(
          PreferencesActions.setUserRoles(result.map((role) => role.id))
        );
      },
      onEmptyResult: () => {
        dispatch(PreferencesActions.setUserRoles(null));
      },
      queryConstraints: [],
    });
  } catch (error) {
    dispatch(PreferencesActions.setError(error.message));
  } finally {
    dispatch(PreferencesActions.setLoading(false));
  }
};

export const StreamAwardCategories = async ({
  dispatch,
}: {
  dispatch: Dispatch;
}) => {
  dispatch(PreferencesActions.setLoading(true));
  dispatch(PreferencesActions.setError(null));
  try {
    streamCollection({
      collectionName: "award_categories",
      onGetResult: (result) => {
        dispatch(PreferencesActions.setAwardCategories(result));
      },
      onEmptyResult: () => {
        dispatch(PreferencesActions.setAwardCategories(null));
      },
      queryConstraints: [],
    });
  } catch (error) {
    dispatch(PreferencesActions.setError(error.message));
  } finally {
    dispatch(PreferencesActions.setLoading(false));
  }
};

export const StreamYearScores = async ({
  dispatch,
  year,
}: {
  dispatch: Dispatch;
  year: Year;
}) => {
  dispatch(PreferencesActions.setLoading(true));
  dispatch(PreferencesActions.setError(null));
  try {
    streamCollection({
      collectionName: `years/${year?.id}/scores`,
      onGetResult: (result) => {
        dispatch(PreferencesActions.setYearScores(result));
      },
      onEmptyResult: () => {
        dispatch(PreferencesActions.setYearScores([]));
      },
      queryConstraints: [],
    });
  } catch (error) {
    dispatch(PreferencesActions.setError(error.message));
  } finally {
    dispatch(PreferencesActions.setLoading(false));
  }
};

export const SaveEventDate = async ({
  dispatch,
  year,
  startDate,
  endDate,
}: {
  dispatch: Dispatch;
  year: Year;
  startDate: Date;
  endDate: Date;
}) => {
  dispatch(PreferencesActions.setLoading(true));
  dispatch(PreferencesActions.setError(null));

  try {
    await updateDoc(doc(db, "years", year.id), {
      event_start_at: startDate,
      event_end_at: endDate,
    });
  } catch (error) {
    dispatch(PreferencesActions.setError(error.message));
    toast.error(error.message);
  } finally {
    dispatch(PreferencesActions.setLoading(false));
  }
};

export const SaveApplicationDate = async ({
  dispatch,
  year,
  startDate,
  endDate,
}: {
  dispatch: Dispatch;
  year: Year;
  startDate: Date;
  endDate: Date;
}) => {
  dispatch(PreferencesActions.setLoading(true));
  dispatch(PreferencesActions.setError(null));
  try {
    await updateDoc(doc(db, "years", year.id), {
      application_open_at: startDate,
      application_close_at: endDate,
    });
  } catch (error) {
    dispatch(PreferencesActions.setError(error.message));
    toast.error(error.message);
  } finally {
    dispatch(PreferencesActions.setLoading(false));
  }
};

export const createAwardCategory = async ({
  dispatch,
  awardCategory,
}: {
  dispatch: Dispatch;
  awardCategory: AwardCategory;
}): Promise<void> => {
  dispatch(PreferencesActions.setLoading(true));
  dispatch(PreferencesActions.setError(null));
  awardCategory["created_at"] = new Date();

  try {
    await addDoc(collection(db, "award_categories"), awardCategory);
    toast.success("Created");
  } catch (error) {
    dispatch(PreferencesActions.setError(error.message));
    toast.error(error.message);
  } finally {
    dispatch(PreferencesActions.setLoading(false));
  }
};

export const saveAwardCategory = async ({
  dispatch,
  awardCategory,
}: {
  dispatch: Dispatch;
  awardCategory: AwardCategory;
}): Promise<void> => {
  dispatch(PreferencesActions.setLoading(true));
  dispatch(PreferencesActions.setError(null));
  try {
    const awardCategoryId = awardCategory?.id;
    delete awardCategory.id;

    await setDoc(doc(db, "award_categories", awardCategoryId), awardCategory);
    toast.success("Saved");
    dispatch(PreferencesActions.setCurrentUpdatingAwardCategory(null));
  } catch (error) {
    dispatch(PreferencesActions.setError(error.message));
    toast.error(error.message);
  } finally {
    dispatch(PreferencesActions.setLoading(false));
  }
};

export const deleteAwardCategory = async ({
  dispatch,
  awardCategory,
}: {
  dispatch: Dispatch;
  awardCategory: AwardCategory;
}): Promise<void> => {
  dispatch(PreferencesActions.setLoading(true));
  dispatch(PreferencesActions.setError(null));
  try {
    await deleteDoc(doc(db, "award_categories", awardCategory.id));
    toast.success("Deleted");
  } catch (error) {
    dispatch(PreferencesActions.setError(error.message));
    toast.error(error.message);
  } finally {
    dispatch(PreferencesActions.setLoading(false));
  }
};

export const createYearScore = async ({
  dispatch,
  year,
  score,
}: {
  dispatch: Dispatch;
  year: Year;
  score: YearScore;
}): Promise<void> => {
  dispatch(PreferencesActions.setLoading(true));
  dispatch(PreferencesActions.setError(null));
  try {
    await addDoc(collection(db, "years", year.id, "scores"), {
      ...score,
    });
    toast.success("Created");
  } catch (error) {
    dispatch(PreferencesActions.setError(error.message));
    toast.error(error.message);
  } finally {
    dispatch(PreferencesActions.setLoading(false));
  }
};

export const saveYearScore = async ({
  dispatch,
  year,
  score,
}: {
  dispatch: Dispatch;
  year: Year;
  score: YearScore;
}): Promise<void> => {
  dispatch(PreferencesActions.setLoading(true));
  dispatch(PreferencesActions.setError(null));
  try {
    const YearId = year.id;
    const yearScoreId = score?.id;
    delete score.id;
    console.log("YearId", YearId);
    console.log("yearScoreId", yearScoreId);

    await setDoc(doc(db, "years", YearId, "scores", yearScoreId), {
      ...score,
    });
    toast.success("Saved");
    dispatch(PreferencesActions.setCurrentUpdatingScore(null));
  } catch (error) {
    dispatch(PreferencesActions.setError(error.message));
    toast.error(error.message);
  } finally {
    dispatch(PreferencesActions.setLoading(false));
  }
};

export const deleteYearScore = async ({
  dispatch,
  year,
  score,
}: {
  dispatch: Dispatch;
  year: Year;
  score: YearScore;
}): Promise<void> => {
  dispatch(PreferencesActions.setLoading(true));
  dispatch(PreferencesActions.setError(null));
  try {
    await deleteDoc(doc(db, "years", year.id, "scores", score.id));
    toast.success("Deleted");
  } catch (error) {
    dispatch(PreferencesActions.setError(error.message));
    toast.error(error.message);
  } finally {
    dispatch(PreferencesActions.setLoading(false));
  }
};

export const createYear = async ({
  dispatch,
  year,
}: {
  dispatch: Dispatch;
  year: Year;
}): Promise<void> => {
  dispatch(PreferencesActions.setLoading(true));
  dispatch(PreferencesActions.setError(null));
  try {
    let yearScores = year.scores;
    delete year.scores;

    await setDoc(doc(db, "years", `${year.id}`), year);

    if (yearScores?.length > 0) {
      for (const year_score of yearScores) {
        const yearScore = year_score;
        delete yearScore.id;
        await addDoc(
          collection(db, "years", `${year.id}`, "scores"),
          yearScore
        );
      }
    }

    toast.success("Created");
  } catch (error) {
    dispatch(PreferencesActions.setError(error.message));
    toast.error(error.message);
  } finally {
    dispatch(PreferencesActions.setLoading(false));
  }
};

export const setYearActive = async ({
  dispatch,
  year_id,
  checked,
}: {
  dispatch: Dispatch;
  year_id: string;
  checked: boolean;
}): Promise<void> => {
  dispatch(PreferencesActions.setLoading(true));
  dispatch(PreferencesActions.setError(null));
  try {
    if (checked) {
      const years = await getDocs(collection(db, "years"));
      for (const year of years.docs) {
        if (year.id !== year_id) {
          // Update all year to inactive
          await updateDoc(doc(db, "years", year.id), {
            active: false,
          });
        } else {
          // Update the year to active
          await updateDoc(doc(db, "years", year_id), {
            active: true,
          });
        }
      }
    } else {
      // Update the year to inactive
      await updateDoc(doc(db, "years", year_id), {
        active: false,
      });
    }
    toast.success("Saved");
  } catch (error) {
    dispatch(PreferencesActions.setError(error.message));
    toast.error(error.message);
  } finally {
    dispatch(PreferencesActions.setLoading(false));
  }
};
