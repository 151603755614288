import CustomModal from "components/CustomModal";
import { doc, updateDoc } from "firebase/firestore";
import { FormikProvider, useFormik } from "formik";
import { isEmpty, isString } from "lodash";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { UserActions, UserSelector } from "redux/slices/user";
import { db, uploadFile } from "utils/firebase";
import { encryptPassword } from "utils/hash";
import * as Yup from "yup";
import UserForm from "./UserForm";

export default function EditProfileModal() {
  const dispatch = useDispatch();
  const { isEditingProfile, user } = UserSelector();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: user?.name,
      password: user?.password,
      profile: user?.profile,
    },
    onSubmit: async (values) => {
      try {
        const updatedData: User = {
          name: values?.name,
          password: values?.password,
          profile: values?.profile,
        };

        // Upload profile image to storage
        if (!isEmpty(values?.profile) && !isString(values?.profile)) {
          const profile_url = await uploadFile(values?.profile, "image");
          updatedData.profile = profile_url;
        }

        // Save user data
        if (updatedData?.password !== user?.password) {
          updatedData.password = await encryptPassword(updatedData?.password);
        }

        await updateDoc(doc(db, "users", user?.id), updatedData);
        dispatch(UserActions.setIsEditingProfile(false));
        formik.resetForm();
        toast.success("Saved");
      } catch (error) {
        toast.error(error.message);
      }
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      password: Yup.string().min(6).required("Password is required"),
    }),
    validateOnBlur: false,
    validateOnChange: false,
  });
  return (
    <>
      {isEditingProfile && (
        <CustomModal
          open={isEditingProfile}
          handleClose={() => {
            dispatch(UserActions.setIsEditingProfile(false));
            formik.resetForm();
          }}
          title={"Edit Profile"}
          // small
        >
          <FormikProvider value={formik}>
            <UserForm />
          </FormikProvider>
        </CustomModal>
      )}
    </>
  );
}
