import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import settings from "redux/slices/setting";
import { ApplicationsReducer } from "./slices/applications";
import { UserReducer } from "./slices/user";
import { UsersReducer } from "./slices/users";
import { PreferencesReducer } from "./slices/preferences";

export const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: ["settings", "user"],
};

export const rootReducer = combineReducers({
  settings,
  user: UserReducer,
  applications: ApplicationsReducer,
  users: UsersReducer,
  preferences: PreferencesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
