import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { streamCollection } from "services/firebase";

type InitialState = {
  isLoading: boolean;
  error: null | string;
  users: null | User[];

  isCreatingUser: boolean;
  currentUpdatingUser: null | User;
  currentViewingUser: null | User;

  filterRoles: {
    value: string;
    label: string;
  }[];

  filterName: string;
};

const initialState: InitialState = {
  isLoading: false,
  error: null,
  users: null,
  isCreatingUser: false,
  currentUpdatingUser: null,
  currentViewingUser: null,
  filterRoles: [],
  filterName: "",
};

export const UsersSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setUsers: (state, action: PayloadAction<User[] | null>) => {
      state.users = action.payload;
    },
    setCreatingUser: (state, action: PayloadAction<boolean>) => {
      state.isCreatingUser = action.payload;
    },
    setCurrentUpdatingUser: (state, action: PayloadAction<User | null>) => {
      state.currentUpdatingUser = action.payload;
    },
    setCurrentViewingUser: (state, action: PayloadAction<User | null>) => {
      state.currentViewingUser = action.payload;
    },
    setFilterRoles: (
      state,
      action: PayloadAction<{ value: string; label: string }[]>
    ) => {
      state.filterRoles = action.payload;
    },
    setFilterName: (state, action: PayloadAction<string>) => {
      state.filterName = action.payload;
    },
  },
});

export const UsersSelector = (): InitialState =>
  useSelector((state: any) => state.users);
export const UsersActions = UsersSlice.actions;
export const UsersReducer = UsersSlice.reducer;

export const StreamUsers = async ({ dispatch }: { dispatch: Dispatch }) => {
  dispatch(UsersActions.setLoading(true));
  dispatch(UsersActions.setError(null));
  try {
    streamCollection({
      collectionName: "users",
      onGetResult: (result) => {
        dispatch(UsersActions.setUsers(result));
      },
      onEmptyResult: () => {
        dispatch(UsersActions.setUsers(null));
      },
      queryConstraints: [],
    });
  } catch (error) {
    dispatch(UsersActions.setError(error.message));
  } finally {
    dispatch(UsersActions.setLoading(false));
  }
};
