import LoadingLayout from "components/LoadingLayout";
import AuthGuard from "guards/auth";
import GuestGuard from "guards/guest";
import { lazy, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingLayout loading={true} children={null} />}>
      <Component {...props} />
    </Suspense>
  );
};

const LazyLoad = (path: string) =>
  Loadable(lazy(() => import(`pages/${path}`)));

export default function AppRoutes() {
  return useRoutes([
    {
      path: "auth",
      element: <GuestGuard />,
      children: [
        { path: "login", element: <LoginPage /> },
        // { path: "register", element: <RegisterPage /> },
        // { path: "reset-password", element: <ResetPasswordPage /> },
        { path: "", element: <Navigate to="/auth/login" /> },
      ],
    },
    {
      path: "",
      element: <AuthGuard />,
      children: [{ path: "", element: <HomePage /> }],
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ]);
}

const NotFoundPage = LazyLoad("404");

// Authentication routes
const LoginPage = LazyLoad("auth/login");
const RegisterPage = LazyLoad("auth/register");
const ResetPasswordPage = LazyLoad("auth/reset-password");

// Home routes
const HomePage = LazyLoad("index");
